// @ts-check
import PropTypes from "prop-types";
import React from "react";

import {manualChanges as manualChangesPropTypes} from "../../../pages/op_management/op_management_proptypes";
import ManualChange from "./manual_change";

/**
 * ManualChanges component used in Manage and BlockedScreen
 * @param {Object} props
 * @param {Array<ManualChange>} props.manualChanges
 * @param {Boolean} [props.saved]
 * @param {Boolean} [props.isBlockScreen]
 * @return {React.ReactElement}
 */
const ManualChanges = ({manualChanges, saved, isBlockScreen}) => {
    return (
        <>
            {manualChanges
                .sort((a, b) => new Date(a.timest).getTime() - new Date(b.timest).getTime())
                .map((change) => (
                    <ManualChange change={change} isBlockScreen={isBlockScreen} key={change.id + change.timest} saved={saved} />
                ))}
        </>
    );
};
ManualChanges.propTypes = {
    manualChanges: PropTypes.arrayOf(manualChangesPropTypes),
    saved: PropTypes.bool,
    isBlockScreen: PropTypes.bool
};

export default ManualChanges;
