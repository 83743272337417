// @ts-check

import {func, object, string} from "prop-types";
import React, {useContext} from "react";
import {Trans} from "react-i18next";

import config from "../../../../config/config.json";
import {DATE_FORMATS, DateContext} from "../../../contexts/dates";
import useStyles from "../manage.styles";
import {slash, TYPE} from "./impacts";

/**
 * Render critical difference of type new
 * @param {object} props
 * @param {AppointmentNew} props.criticalDifference
 * @param {String} props.patientName
 * @param {String} props.surgeonName
 * @param {Function} props.onMoveToOp
 * @return {React.ReactElement}
 */
const New = ({criticalDifference, patientName, surgeonName, onMoveToOp}) => {
    const {classes, cx} = useStyles();
    const {formatFromISO} = useContext(DateContext);
    const key = `${TYPE.new}-${criticalDifference.id}`;
    return (
        <div className={classes.textImpacts}>
            <div className={cx(classes.innerRow, classes.rowNew)}>
                <span
                    className={cx(classes.op, {
                        [classes.emergency]: criticalDifference.prio > config.EMERGENCY_PRIORITY_HIGHER_THAN
                    })}
                    role={"link"}
                    tabIndex={0}
                    onClick={() => onMoveToOp(criticalDifference, TYPE.new)}
                    onKeyDown={(e) => e.key === "Enter" && onMoveToOp(criticalDifference, TYPE.new)}
                >
                    <strong data-testid={key + "-name"}>
                        {surgeonName}
                        {slash}
                        {patientName}
                    </strong>
                </span>
                &nbsp;
                {criticalDifference.internalTimestamps && (
                    <Trans
                        components={{bold: <strong />}}
                        i18nKey="Manage.planned"
                        values={{
                            date: formatFromISO(criticalDifference.internalTimestamps.duraRoomLockPre.dtStart, DATE_FORMATS.DATE_SHORT),
                            time: formatFromISO(criticalDifference.internalTimestamps.duraRoomLockPre.dtStart, DATE_FORMATS.TIME)
                        }}
                    />
                )}
            </div>
        </div>
    );
};

New.propTypes = {
    criticalDifference: object.isRequired,
    patientName: string.isRequired,
    surgeonName: string.isRequired,
    onMoveToOp: func.isRequired
};

export default New;
