// @ts-check
import {Done} from "@mui/icons-material";
import PropTypes from "prop-types";
import React, {useContext} from "react";
import {Trans, useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import config from "../../../../config/config.json";
import {DATE_FORMATS, DateContext} from "../../../contexts/dates";
import {
    changeDate,
    changeHighlightedOp,
    loadOpManagementScheduleAction,
    setScrollTo
} from "../../../pages/op_management/op_management_actions";
import {manualChanges as manualChangesPropTypes} from "../../../pages/op_management/op_management_proptypes";
import {selectSelectedDate, selectUsernames} from "../../../pages/op_management/op_management_selectors";
import {selectCurrentOrganizationId} from "../../../redux/app_selectors";
import formatUsername from "../../../utils/format_username";
import {buildSurgeonName} from "../../private_data/helpers";
import {selectStandardName} from "../../private_data/private_data_selectors";
import {selectRoomInfos} from "../../rooms/rooms_selectors";
import useStyles from "../manage.styles";

/**
 * ManualChange component
 * @param {Object} props
 * @param {ManualChange} props.change
 * @param {Boolean} [props.saved]
 * @param {Boolean} [props.isBlockScreen]
 * @return {React.ReactElement}
 */
const ManualChange = ({change, saved, isBlockScreen}) => {
    const {t} = useTranslation();
    const {classes, cx} = useStyles();
    const dispatch = useDispatch();
    const {areSame, formatFromISO, fromISO} = useContext(DateContext);

    const {
        TEXT_PUNCTUATION: {VERTICAL_SLASH}
    } = config;
    const {surgeon, mentor, surgeryApprentice} = change.after.participants;

    // Redux
    const usernames = useSelector(selectUsernames);
    /** @type {Array<{id: string, name: string}>} */
    const roomInfos = useSelector(selectRoomInfos);
    const selectedDate = useSelector(selectSelectedDate);
    const organizationId = useSelector(selectCurrentOrganizationId);

    const surgeon1Name = useSelector(selectStandardName({id: surgeon?.surgeon1, type: "practitioner"}));
    const surgeryApprentice1Name = useSelector(selectStandardName({id: mentor?.mentor1, type: "practitioner"}));
    const mentor1Name = useSelector(selectStandardName({id: surgeryApprentice?.surgeryApprentice1, type: "practitioner"}));

    /**
     * handler to moving op position
     */
    const handleMoveToOp = () => {
        const opStart =
            change.after.internalTimestamps &&
            change.after.internalTimestamps.duraRoomLockPre &&
            change.after.internalTimestamps.duraRoomLockPre.dtStart;
        const opRoom = change.after.location;

        if (opStart && opRoom) {
            if (!areSame(fromISO(opStart), selectedDate, "day")) {
                dispatch(loadOpManagementScheduleAction(organizationId, formatFromISO(opStart, DATE_FORMATS.SYSTEM_DATE)));
                dispatch(changeDate(fromISO(opStart)));
            }
            // Set highlighted Op
            dispatch(changeHighlightedOp({id: change.id, opRoom, opStart}));

            // Set scrollTo as true
            dispatch(setScrollTo(true));
        }
    };

    const newRoomLockStart = change.after.internalTimestamps.duraRoomLockPre.dtStart;
    const oldRoomLockStart = change.before.internalTimestamps.duraRoomLockPre.dtStart;

    const differences = [];
    let isDateOrTimeChanged = false;
    const isSameDate = areSame(fromISO(newRoomLockStart), fromISO(oldRoomLockStart), "day");
    // Set new Date if date was changed
    if (!isSameDate) {
        isDateOrTimeChanged = true;
        differences.push(
            t("Manage.diffNewStartDateTime", {
                newStartDate: formatFromISO(newRoomLockStart, DATE_FORMATS.DATE_SHORT),
                newStartTime: formatFromISO(newRoomLockStart, DATE_FORMATS.TIME)
            })
        );
    }

    // Set new time if time was changed but date not
    if (newRoomLockStart !== oldRoomLockStart && isSameDate) {
        isDateOrTimeChanged = true;
        differences.push(
            t("Manage.diffNewStartTime", {
                newStartTime: formatFromISO(newRoomLockStart, DATE_FORMATS.TIME)
            })
        );
    }

    // Set new room if changed
    if (change.after.location && change.after.location !== change.before.location) {
        const roomName = roomInfos.find((item) => item.id === change.after.location);
        differences.push(
            t(differences.length ? "Manage.diffLocationWithDateTime" : "Manage.diffLocation", {
                location: roomName ? roomName.name : change.after.location
            })
        );
    }

    // set new duration if changed more than 1 min.
    const oldDuration = change.before.internalTimestamps.duraRoomLockPost.refEnd;
    const newDuration = change.after.internalTimestamps.duraRoomLockPost.refEnd;
    if (Math.abs(oldDuration - newDuration) > 60) {
        differences.push(
            t(isDateOrTimeChanged ? "Manage.diffDurationWithOtherChanges" : "Manage.diffDuration", {
                duration: Math.round(newDuration / 60)
            })
        );
    }
    const surgeonName = buildSurgeonName(
        {id: surgeon?.surgeon1, name: surgeon1Name},
        {id: surgeryApprentice?.surgeryApprentice1, name: surgeryApprentice1Name},
        {id: mentor?.mentor1, name: mentor1Name}
    );

    return (
        <div
            className={cx(classes.row, {
                [classes.rowBlockScreen]: isBlockScreen
            })}
            key={change.after.personId + differences.join("-")}
        >
            <div>
                <div className={classes.subtext}>
                    {t("Manage.oclock", {
                        time: `${formatFromISO(change.timest, DATE_FORMATS.DATE_SHORT)} ${formatFromISO(change.timest, DATE_FORMATS.TIME)}`
                    })}
                    &nbsp;&middot;&nbsp;
                    <strong>{formatUsername(change.userEmail, usernames)}</strong>
                </div>
                <div className={classes.innerRow}>
                    <span
                        className={classes.op}
                        role={"button"}
                        tabIndex={0}
                        onClick={handleMoveToOp}
                        onKeyDown={(e) => e.key === "Enter" && handleMoveToOp()}
                    >
                        <strong>{t("Manage.op")}</strong>
                        {t("Manage.of")}
                        <strong>{surgeonName}</strong>
                    </span>
                    &nbsp;
                    <Trans components={{bold: <strong />}} i18nKey="Manage.changed" values={{diff: differences.join(" ")}} />
                </div>
                <div className={classes.space} />
                <strong>{t("Manage.reason")}</strong>
                {change.reasonForChange.map((reason) => t(`ReasonForChange.${reason}`)).join(` ${VERTICAL_SLASH}`)}
            </div>
            {/* Undo icon should be shown in the future */}
            {saved ? <Done className={classes.changeIcon} color="primary" /> : null}
        </div>
    );
};
ManualChange.propTypes = {
    change: manualChangesPropTypes,
    saved: PropTypes.bool,
    isBlockScreen: PropTypes.bool
};

export default ManualChange;
