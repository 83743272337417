// @ts-check

import {func, object, string} from "prop-types";
import React, {useContext} from "react";
import {Trans, useTranslation} from "react-i18next";

import {DATE_FORMATS, DateContext} from "../../../contexts/dates";
import useStyles from "../manage.styles";
import {slash, TYPE} from "./impacts";

/**
 * Render critical difference of type missing
 * @param {object} props
 * @param {AppointmentMissing} props.criticalDifference
 * @param {String} props.patientName
 * @param {String} props.surgeonName
 * @param {Function} props.onMoveToOp
 * @return {React.ReactElement}
 */
const Missing = ({criticalDifference, patientName, surgeonName, onMoveToOp}) => {
    const {classes, cx} = useStyles();
    const {t} = useTranslation();
    const {formatFromISO} = useContext(DateContext);
    const key = `${TYPE.missing}-${criticalDifference.id}`;
    return (
        <div className={classes.textImpacts} data-testid={key}>
            <div className={classes.innerRow}>
                <span
                    className={cx(classes.op, classes.missing)}
                    data-testid={key + "-link"}
                    role={"link"}
                    tabIndex={0}
                    onClick={() => onMoveToOp(criticalDifference, TYPE.missing)}
                    onKeyDown={(e) => e.key === "Enter" && onMoveToOp(criticalDifference, TYPE.missing)}
                >
                    <strong data-testid={key + "-name"}>
                        {surgeonName}
                        {slash}
                        {patientName}
                    </strong>
                </span>
                &nbsp;
                {criticalDifference.internalTimestamps ? (
                    <Trans
                        components={{bold: <strong />}}
                        i18nKey="Manage.missing"
                        values={{
                            time: formatFromISO(criticalDifference.internalTimestamps.duraRoomLockPre.dtStart, DATE_FORMATS.TIME)
                        }}
                    />
                ) : (
                    t("Manage.missingWithoutDate")
                )}
            </div>
        </div>
    );
};

Missing.propTypes = {
    criticalDifference: object.isRequired,
    patientName: string.isRequired,
    surgeonName: string.isRequired,
    onMoveToOp: func.isRequired
};

export default Missing;
