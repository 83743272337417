// @ts-check
import PropTypes from "prop-types";
import React, {Fragment, useContext} from "react";
import {Trans, useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {DATE_FORMATS, DateContext} from "../../../contexts/dates";
import {
    changeDate,
    changeHighlightedOp,
    loadOpManagementScheduleAction,
    setScrollTo
} from "../../../pages/op_management/op_management_actions";
import {selectSelectedDate} from "../../../pages/op_management/op_management_selectors";
import {selectCurrentOrganizationId} from "../../../redux/app_selectors";
import {buildSurgeonName} from "../../private_data/helpers";
import {selectFullNamesArray, selectStandardNamesArray} from "../../private_data/private_data_selectors";
import getIdsFromCriticalDifferences from "../../private_data/utils/get_ids_from_critical_differences";
import useStyles from "../manage.styles";
import Missing from "./missing";
import Moved from "./moved";
import New from "./new";

/**
 * @typedef {Object} SortProperties
 * @property {String} sortKey
 * @property {DifferenceType} type
 */
/** @typedef {SortProperties & AppointmentMissing} MissingWithSortKey */
/** @typedef {SortProperties & AppointmentMoved} MovedWithSortKey */
/** @typedef {SortProperties & AppointmentNew} NewWithSortKey */

/**
 * type of critical differences
 * @type {{missing: "missing", new: "new", moved: "moved"}}
 */
export const TYPE = {
    missing: "missing",
    new: "new",
    moved: "moved"
};

export const slash = <Fragment>&nbsp;/&nbsp;</Fragment>;

/**
 * Impact i.e. criticalDifferences
 * @param {Object} props
 * @param {AppointmentChanged} props.differences
 * @return {React.ReactElement}
 */
const Impacts = ({differences}) => {
    const {classes} = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {areSame, fromISO, formatFromISO, format, now} = useContext(DateContext);

    // redux store
    const selectedDate = useSelector(selectSelectedDate);
    const organizationId = useSelector(selectCurrentOrganizationId);
    const surgeonNames = useSelector(
        selectStandardNamesArray({ids: getIdsFromCriticalDifferences(differences, "participants"), type: "practitioner"})
    );
    const patientNames = useSelector(selectFullNamesArray({ids: getIdsFromCriticalDifferences(differences, "personId"), type: "patient"}));

    /**
     * handler to moving op position
     * @param {(AppointmentMissing&AppointmentMoved&AppointmentNew)} op
     * @param {DifferenceType} type
     * @return {void}
     */
    const handleMoveToOp = (op, type) => {
        const opStart = type === TYPE.moved ? op.startNew : op.internalTimestamps?.duraRoomLockPre?.dtStart;
        const opRoom = op.location;
        if (opStart && opRoom) {
            if (!areSame(fromISO(opStart), selectedDate, "day")) {
                dispatch(loadOpManagementScheduleAction(organizationId, formatFromISO(opStart, DATE_FORMATS.SYSTEM_DATE)));
                dispatch(changeDate(fromISO(opStart)));
            }
            // Set highlighted Op
            dispatch(changeHighlightedOp({id: op.id, opRoom, opStart}));

            // Set scrollTo as true
            dispatch(setScrollTo(true));
        }
    };

    // Concatenate and sort all differences
    const moved = differences.moved
        ? differences.moved.map((criticalDifferenceMoved) => ({
              ...criticalDifferenceMoved,
              type: TYPE.moved,
              sortKey: criticalDifferenceMoved.startOld
          }))
        : [];
    const missing = differences.missing
        ? differences.missing.map((criticalDifferenceMissing) => ({
              ...criticalDifferenceMissing,
              /** @type {DifferenceType} */
              type: TYPE.missing,
              sortKey: criticalDifferenceMissing.internalTimestamps?.duraRoomLockPre?.dtStart
          }))
        : [];
    const newOps = differences.new
        ? differences.new.map((criticalDifferenceNew) => ({
              ...criticalDifferenceNew,
              type: TYPE.new,
              sortKey: criticalDifferenceNew.internalTimestamps?.duraRoomLockPre?.dtStart
          }))
        : [];

    const allItems = [...moved, ...missing, ...newOps];

    // Sort items (null will be listed at the end)
    const sortedAllItems = allItems.sort((a, b) => {
        if (a.sortKey === b.sortKey) return 0;
        if (a.sortKey === null) return 1;
        if (b.sortKey === null) return -1;
        return fromISO(a.sortKey) < fromISO(b.sortKey) ? -1 : 1;
    });

    let prevDate = "init";

    const criticalDifferencesJSX = sortedAllItems.map((criticalDifference) => {
        let formatted = null;

        const {participants} = criticalDifference;
        const {surgeon, mentor, surgeryApprentice} = participants || {};
        // Format surgeon name
        const surgeon1Name = surgeon && surgeonNames.find((el) => el.id === surgeon.surgeon1)?.name;
        const mentor1Name = mentor && surgeonNames.find((el) => el.id === mentor.mentor1)?.name;
        const surgeryApprentice1Name = surgeryApprentice && surgeonNames.find((el) => el.id === surgeryApprentice.surgeryApprentice1)?.name;

        const surgeonNameFormatted = buildSurgeonName(
            {id: surgeon?.surgeon1, name: surgeon1Name},
            {id: surgeryApprentice?.surgeryApprentice1, name: surgeryApprentice1Name},
            {id: mentor?.mentor1, name: mentor1Name}
        );
        // Format patient name
        const patientNameFormatted = patientNames.find((el) => el.id === criticalDifference.personId)?.name || t("App.not_available_short");

        if (criticalDifference.type === TYPE.missing) {
            formatted = (
                <Missing
                    criticalDifference={criticalDifference}
                    key={`${TYPE.missing}-${criticalDifference.id}`}
                    patientName={patientNameFormatted}
                    surgeonName={surgeonNameFormatted}
                    onMoveToOp={handleMoveToOp}
                />
            );
        }
        if (criticalDifference.type === TYPE.moved) {
            // @ts-ignore
            const startNewDT = fromISO(criticalDifference.startNew);
            // @ts-ignore
            const startOldDT = fromISO(criticalDifference.startOld);
            let changeDateTime;
            if (areSame(startNewDT, startOldDT, "day")) {
                changeDateTime = (
                    <Trans
                        components={{bold: <strong />}}
                        i18nKey="Manage.movedTime"
                        values={{time: format(startNewDT, DATE_FORMATS.TIME)}}
                    />
                );
            } else {
                changeDateTime = (
                    <Trans
                        components={{bold: <strong />}}
                        i18nKey="Manage.movedDateTime"
                        values={{
                            date: format(startNewDT, DATE_FORMATS.DATE_SHORT),
                            time: format(startNewDT, DATE_FORMATS.TIME)
                        }}
                    />
                );
            }
            formatted = (
                <Moved
                    changeDateTime={changeDateTime}
                    // @ts-ignore
                    criticalDifference={criticalDifference}
                    key={`${TYPE.moved}-${criticalDifference.id}`}
                    patientName={patientNameFormatted}
                    surgeonName={surgeonNameFormatted}
                    onMoveToOp={handleMoveToOp}
                />
            );
        }
        if (criticalDifference.type === TYPE.new) {
            formatted = (
                <New
                    // @ts-ignore
                    criticalDifference={criticalDifference}
                    key={`${TYPE.new}-${criticalDifference.id}`}
                    patientName={patientNameFormatted}
                    surgeonName={surgeonNameFormatted}
                    onMoveToOp={handleMoveToOp}
                />
            );
        }
        let showDate =
            prevDate === "init" ||
            (!areSame(fromISO(prevDate), fromISO(criticalDifference.sortKey), "day") &&
                prevDate !== null &&
                criticalDifference.sortKey !== null);
        if (prevDate !== null && criticalDifference.sortKey === null) {
            showDate = true;
        }

        prevDate = criticalDifference.sortKey;
        if (showDate) {
            const sortKeyDT = fromISO(criticalDifference.sortKey);
            const isToday = areSame(sortKeyDT, now(), "day");
            const fromDate = format(sortKeyDT, DATE_FORMATS.DATE_SHORT);
            return (
                <Fragment key={fromDate}>
                    <div className={classes.textImpactDate}>
                        {criticalDifference.sortKey
                            ? isToday
                                ? t("Manage.planChangesOfToday")
                                : t("Manage.planChangesOf", {date: fromDate})
                            : t("App.unknown")}
                    </div>
                    {formatted}
                </Fragment>
            );
        }
        return formatted;
    });
    return <div>{criticalDifferencesJSX}</div>;
};
Impacts.propTypes = {
    differences: PropTypes.shape({
        moved: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
                startOld: PropTypes.string.isRequired,
                startNew: PropTypes.string.isRequired,
                participants: PropTypes.object,
                personId: PropTypes.string,
                prio: PropTypes.number
            })
        ),
        missing: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
                participants: PropTypes.object,
                personId: PropTypes.string,
                location: PropTypes.string,
                internalTimestamps: PropTypes.object
            })
        ),
        new: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
                participants: PropTypes.object.isRequired,
                personId: PropTypes.string.isRequired,
                internalTimestamps: PropTypes.object,
                prio: PropTypes.number
            })
        )
    })
};

export default Impacts;
