// @ts-check

import {element, func, object, string} from "prop-types";
import React from "react";

import config from "../../../../config/config.json";
import useStyles from "../manage.styles";
import {slash, TYPE} from "./impacts";

/**
 * Render critical difference of type moved
 * @param {object} props
 * @param {AppointmentMoved} props.criticalDifference
 * @param {String} props.patientName
 * @param {String} props.surgeonName
 * @param {Function} props.onMoveToOp
 * @param {React.ReactElement} props.changeDateTime
 * @return {React.ReactElement}
 */
const Moved = ({criticalDifference, patientName, surgeonName, onMoveToOp, changeDateTime}) => {
    const {classes, cx} = useStyles();
    const key = `${TYPE.moved}-${criticalDifference.id}`;
    return (
        <div className={classes.textImpacts} data-testid={key}>
            <div className={classes.innerRow}>
                <span
                    className={cx(classes.op, {
                        [classes.emergency]: criticalDifference.prio > config.EMERGENCY_PRIORITY_HIGHER_THAN
                    })}
                    role={"link"}
                    tabIndex={0}
                    onClick={() => onMoveToOp(criticalDifference, TYPE.moved)}
                    onKeyDown={(e) => e.key === "Enter" && onMoveToOp(criticalDifference, TYPE.moved)}
                >
                    <strong data-testid={key + "-name"}>
                        {surgeonName}
                        {slash}
                        {patientName}
                    </strong>
                </span>
                &nbsp;
                {changeDateTime}
            </div>
        </div>
    );
};

Moved.propTypes = {
    criticalDifference: object.isRequired,
    patientName: string.isRequired,
    surgeonName: string.isRequired,
    onMoveToOp: func.isRequired,
    changeDateTime: element.isRequired
};

export default Moved;
