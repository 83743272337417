// @ts-check
import {ReportProblem} from "@mui/icons-material";
import {Button} from "@mui/material";
import React, {Fragment, useContext, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {EVENTS} from "../../../config/event_config";
import {DATE_FORMATS, DateContext} from "../../contexts/dates";
import {
    completeManualChangesAction,
    discardManualChangesAction,
    getUsernamesAction,
    publishPlanAction,
    requestScheduleAction
} from "../../pages/op_management/op_management_actions";
import {
    selectCompleteStatus,
    selectCriticalChanges,
    selectDiscardStatus,
    selectManualChanges,
    selectSavedManualChanges,
    selectStatus,
    selectStatusNewSchedule,
    selectStatusPublishPlan
} from "../../pages/op_management/op_management_selectors";
import {selectCurrentOrganizationId, selectCurrentUserEmail} from "../../redux/app_selectors";
import {isPending} from "../../redux/utils/status";
import {PERMISSION, useSecurity} from "../../utils/security";
import {selectSaveEditOpStatus} from "../op_edit_layer/op_edit_layer_selectors";
import InfoBlock from "../shared/info_block/info_block";
import Loading from "../shared/loading/loading";
import PermissionTooltip from "../shared/permission_tooltip/permission_tooltip";
import Impacts from "./components/impacts";
import ManualChanges from "./components/manual_changes";
import useStyles from "./manage.styles";

export const Manage = () => {
    const {classes, cx} = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {isGranted} = useSecurity();
    const {formatFromISO} = useContext(DateContext);

    // Redux store
    const manualChanges = useSelector(selectManualChanges);
    const status = useSelector(selectStatus);
    const statusPublishPlan = useSelector(selectStatusPublishPlan);
    const statusNewSchedule = useSelector(selectStatusNewSchedule);
    const saveStatus = useSelector(selectSaveEditOpStatus);
    const email = useSelector(selectCurrentUserEmail);
    const discardStatus = useSelector(selectDiscardStatus);
    const organizationId = useSelector(selectCurrentOrganizationId);
    const completeStatus = useSelector(selectCompleteStatus);
    const savedManualChanges = useSelector(selectSavedManualChanges);
    const solvedCriticalChanges = useSelector(selectCriticalChanges);

    useEffect(() => {
        if (manualChanges?.length || savedManualChanges?.length) {
            dispatch(getUsernamesAction());
        }
    }, [manualChanges, savedManualChanges]);

    const handleClickPublish = () => {
        dispatch(publishPlanAction(status.session._id, email));
    };
    const handleNewRequest = () => {
        dispatch(requestScheduleAction(organizationId));
    };
    const handleReset = () => {
        dispatch(
            discardManualChangesAction({
                sessionId: status.session._id,
                userEmail: email
            })
        );
    };

    const handleCompleteManualChanges = () => {
        dispatch(
            completeManualChangesAction(
                {
                    userEmail: email,
                    sessionId: status.session._id
                },
                false
            )
        );
    };

    const actionPending = isPending(discardStatus) || isPending(completeStatus) || isPending(statusPublishPlan);
    // Do not show loading status for new schedule

    let numCriticalDifferences = 0;
    if (typeof status.lastKiSchedule.criticalDifferences !== "undefined") {
        const numMoved = status.lastKiSchedule.criticalDifferences.moved?.length || 0;
        const numMissing = status.lastKiSchedule.criticalDifferences.missing?.length || 0;
        const numNew = status.lastKiSchedule.criticalDifferences.new?.length || 0;
        numCriticalDifferences = numMoved + numMissing + numNew;
    }

    return (
        <div className={classes.root}>
            {actionPending ? (
                <Loading />
            ) : (
                <div className={classes.contentWrapper}>
                    <div className={classes.title}>
                        <div>{t("Manage.title")}</div>
                        {status.transitions?.includes(EVENTS.PublishSession) && (
                            <PermissionTooltip isAllowed={isGranted(PERMISSION.MODIFY_PLAN)}>
                                <Button
                                    className={classes.button}
                                    color="primary"
                                    data-testid="publishButton"
                                    disabled={
                                        isPending(statusPublishPlan) || isPending(statusNewSchedule) || !isGranted(PERMISSION.MODIFY_PLAN)
                                    }
                                    key="publish"
                                    variant="contained"
                                    onClick={handleClickPublish}
                                >
                                    {t("Manage.publish")}
                                </Button>
                            </PermissionTooltip>
                        )}
                        {status.transitions?.includes(EVENTS.ManualRequestSchedulerPlanUncritical) && (
                            <PermissionTooltip isAllowed={isGranted(PERMISSION.MODIFY_PLAN)}>
                                <Button
                                    className={classes.button}
                                    color="primary"
                                    data-testid="calculatePlanButton"
                                    disabled={
                                        isPending(statusPublishPlan) || isPending(statusNewSchedule) || !isGranted(PERMISSION.MODIFY_PLAN)
                                    }
                                    key="newRequest"
                                    variant="contained"
                                    onClick={handleNewRequest}
                                >
                                    {t("Manage.calculateNew")}
                                </Button>
                            </PermissionTooltip>
                        )}
                        {status.session.editedBy === email && (
                            <PermissionTooltip isAllowed={isGranted(PERMISSION.MODIFY_PLAN)}>
                                <div className={classes.buttonWrapper}>
                                    {status.transitions?.includes(EVENTS.DiscardManualChanges) && (
                                        <Button
                                            className={classes.button}
                                            color="primary"
                                            data-testid="resetButton"
                                            disabled={isPending(discardStatus)}
                                            key="discardManualChanges"
                                            variant="contained"
                                            onClick={handleReset}
                                        >
                                            {t("Manage.reset")}
                                        </Button>
                                    )}
                                    {status.transitions?.includes(EVENTS.CompleteManualChanges) && (
                                        <Button
                                            className={cx(classes.button, classes.ml1)}
                                            color="primary"
                                            data-testid="completeButton"
                                            disabled={isPending(saveStatus) || !isGranted(PERMISSION.MODIFY_PLAN)}
                                            key="completeManualChanges"
                                            variant="contained"
                                            onClick={handleCompleteManualChanges}
                                        >
                                            {t("Manage.completeManualChanges")}
                                        </Button>
                                    )}
                                </div>
                            </PermissionTooltip>
                        )}
                    </div>
                    <div className={classes.divider} />
                    {!status.session.published && Boolean(manualChanges?.length) && (
                        <Fragment>
                            <div className={classes.content}>
                                <InfoBlock
                                    title={t("Manage.newManualChanges", {
                                        count: manualChanges?.length
                                    })}
                                >
                                    <ManualChanges manualChanges={manualChanges} />
                                </InfoBlock>
                            </div>
                            <div className={classes.divider} />
                        </Fragment>
                    )}
                    {!status.session.published && Boolean(savedManualChanges?.length) && (
                        <Fragment>
                            <div className={classes.content} data-testid="completeManualChanges">
                                <InfoBlock
                                    saved
                                    title={t("Manage.savedManualChanges", {
                                        count: savedManualChanges?.length
                                    })}
                                >
                                    <ManualChanges manualChanges={savedManualChanges} saved />
                                </InfoBlock>
                            </div>
                            <div className={classes.divider} />
                        </Fragment>
                    )}
                    {!status.session.published && solvedCriticalChanges && Boolean(solvedCriticalChanges.length) && (
                        <Fragment>
                            <div className={classes.content} data-testid={"solvedEvents"}>
                                <InfoBlock title={t("Manage.criticalChanges", {count: solvedCriticalChanges.length})}>
                                    <div className={classes.textWrapper}>
                                        {solvedCriticalChanges
                                            .sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
                                            .map((item) => {
                                                const text = t([`Manage.${item.event}-2`, "Manage.unknown"], {
                                                    time: formatFromISO(item.createdAt, DATE_FORMATS.TIME)
                                                });
                                                return (
                                                    <div
                                                        className={cx(classes.textEvent, classes.mbXS, {
                                                            [classes.textEmergency]: item.event === "CriticalEventRegisterEmergency"
                                                        })}
                                                        key={item.createdAt}
                                                    >
                                                        {formatFromISO(item.createdAt, DATE_FORMATS.DATE_SHORT)}
                                                        &nbsp;
                                                        <span>{t(`Manage.${item.event}-1`)}&nbsp;</span>
                                                        {item.event === "CriticalEventRegisterEmergency" && (
                                                            <ReportProblem className={cx(classes.criticalEventIcon)} />
                                                        )}
                                                        {text}
                                                    </div>
                                                );
                                            })}
                                    </div>
                                </InfoBlock>
                            </div>
                            <div className={classes.divider} />
                        </Fragment>
                    )}
                    {!status.session.published && Boolean(numCriticalDifferences) && global.DISABLE_CRITICAL_DIFFERENCES !== "1" && (
                        <Fragment>
                            <div className={classes.content} data-testid={"criticalDifferences"}>
                                <InfoBlock title={t("Manage.criticalDifferences", {count: numCriticalDifferences})}>
                                    <Impacts differences={status.lastKiSchedule.criticalDifferences} />
                                </InfoBlock>
                            </div>
                            <div className={classes.divider} />
                        </Fragment>
                    )}
                </div>
            )}
        </div>
    );
};

export default Manage;
