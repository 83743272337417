import {makeStyles} from "tss-react/mui";

/**
 * @todo #14788: theme.typography throws error, type hint needs to be fixed
 * @type {object}
 */
export default makeStyles()((theme) => ({
    root: {
        width: "100%",
        height: "100%",
        margin: `0 0 2.5rem 0`,
        display: "flex"
    },
    contentWrapper: {
        color: theme.palette.grey[900],
        width: "100%",
        overflow: "auto"
    },
    title: {
        ...theme.custom.rightPanel.title
    },
    divider: {
        ...theme.custom.rightPanel.divider
    },
    content: {
        marginTop: "1.875rem", // 30px
        marginBottom: "0.675rem", // 10px
        width: "100%",
        fontSize: "0.85rem",
        padding: "0 2.5rem 0 2.5rem"
    },
    changeIcon: {
        fontSize: "1.5rem",
        marginTop: theme.spacing("m")
    },
    deleteIcon: {
        fontSize: "1.5rem",
        cursor: "pointer"
    },
    row: {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: theme.spacing("m")
    },
    rowBlockScreen: {
        padding: "16px 40px",
        marginBottom: 0
    },
    op: {
        color: theme.palette.primary.main,
        cursor: "pointer"
    },
    missing: {
        color: theme.palette.grey[500]
    },
    emergency: {
        color: theme.custom.emergency.main
    },
    innerRow: {
        width: "24rem"
    },
    innerRowBlockScreen: {
        width: "22rem"
    },
    rowNew: {
        "& b": {
            color: theme.custom.emergency.main
        }
    },
    text: {
        fontSize: "0.875rem",
        color: theme.palette.text.disabled,
        width: "22rem",
        marginBottom: "1.25rem",
        lineHeight: theme.spacing("m")
    },
    textImpacts: {
        fontSize: "0.875rem",
        color: theme.palette.text.disabled,
        width: "24rem",
        lineHeight: "1.25rem",
        marginBottom: "0.25rem"
    },
    textImpactDate: {
        "fontSize": "0.75rem",
        "color": theme.palette.text.disabled,
        "marginTop": theme.spacing("m"),
        "&:first-of-type": {
            marginTop: 0
        }
    },
    textEvent: {
        fontSize: "0.875rem",
        color: theme.palette.text.disabled,
        width: "22rem",
        marginBottom: "1.25rem"
    },
    textEmergency: {
        "& span": {
            color: theme.custom.emergency.main,
            fontWeight: theme.typography.fontWeightBold
        }
    },
    mbXS: {
        marginBottom: theme.spacing("xs")
    },
    space: {
        marginBottom: theme.spacing("s")
    },
    subtext: {
        "fontSize": "0.6875rem", // 11px
        "marginBottom": theme.spacing("s"),
        "& span": {
            color: theme.palette.primary.main
        }
    },
    buttonWrapper: {
        display: "flex",
        margin: `${theme.spacing("l")} 0`
    },
    button: {
        fontSize: "0.875rem",
        fontWeight: theme.typography.fontWeightMedium,
        padding: `${theme.spacing("xs")} 1rem`,
        minWidth: "8rem"
    },
    ml1: {
        marginLeft: theme.spacing("m")
    },
    mt1: {
        marginTop: theme.spacing("m")
    },
    manualChangesRoot: {
        marginTop: theme.spacing("m")
    },
    criticalEventIcon: {
        color: theme.palette.common.white,
        fontSize: "0.75rem",
        backgroundColor: theme.custom.emergency.main,
        borderRadius: "0.4375rem",
        marginRight: "0.25rem",
        marginTop: "0.125rem",
        padding: "1px"
    },
    defaultCursor: {
        cursor: "default"
    }
}));
